import { createContext } from 'react'

const authContext = createContext({
    authenticated: false,
    user: {},
    login: () => { },
    logout: () => { },
    test: () => { },
    isAuthenticated: () => { },
    loginCheck: () => { },
    api: {},
})

export const AuthProvider = authContext.Provider;
export const AuthConsumer = authContext.Consumer;
export const AuthContext = authContext;