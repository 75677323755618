import React from 'react';
import { TextField, Button, Paper, Modal } from '@material-ui/core';
import { AuthContext } from '../../AuthContext';
import Picker from 'emoji-picker-react'


export default class CreateLocationForm extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        console.log(props);

        if (this.props.location) {
            console.log('Editing location')
            this.state = {
                _id: this.props.location._id,
                name: this.props.location.name,
                lat: this.props.location.position.lat,
                lng: this.props.location.position.lng,
                description: this.props.location.description,
                textIcon: this.props.location.textIcon,
            }
        } else {
            this.state = {
                name: "",
                lat: this.props.position.lat,
                lng: this.props.position.lng,
                description: "",
                textIcon: "",
            }
        }
        this.state.errors = {}
        this.state.editMapIconModalOpen = false

        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    handleChange(key) {
        return (event) => { this.setState({ [key]: event.target.value }); }
    }

    validate() {
        let valid = true;
        const errors = {}
        if (!this.state.name.trim()) {
            valid = false
            errors["name"] = "Name is required"
        }
        if (!this.state.lat || isNaN(this.state.lat)) {
            valid = false
            errors["lat"] = "Latitude is required"
        }
        if (!isNaN(this.state.lat) && Math.abs(this.state.lat) > 90) {
            valid = false
            errors["lat"] = "Latitude must be between -90 and 90"
        }
        if (!this.state.lng || isNaN(this.state.lng)) {
            valid = false
            errors["lng"] = "Longitude is required"
        }
        this.setState({ errors: errors })
        console.log("valid", valid)
        return valid;
    }

    handleSubmit() {
        if (this.validate()) {
            let newLocation = {
                name: this.state.name,
                position: {
                    lat: parseFloat(this.state.lat),
                    lng: parseFloat(this.state.lng)
                },
                description: this.state.description,
                textIcon: this.state.textIcon,
            }
            if (this.state._id) {
                newLocation._id = this.state._id
            }
            const method = newLocation._id ? 'PUT' : 'POST'
            const url = newLocation._id ? `/api/map/${this.props.mapId}/location/${newLocation._id}` : '/api/map/location'
            this.context.api.fetch(method, url, {
                mapId: this.props.mapId,
                location: newLocation
            }, true)
                .then(data => {
                    if (data.worked) {
                        console.log("Saving...", data)
                        this.props.onSave(data)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    handleCancel() {
        this.props.onCancel()
    }

    handleDelete() {
        const deleteRelatedEvents = true;
        if (window.confirm("Are you sure you would like to delete this location and all of its events?")) {
            if (this.state._id) {
                this.context.api.delete(`/api/map/${this.props.mapId}/location/${this.state._id}`, {
                    deleteRelatedEvents: deleteRelatedEvents
                }, true)
                    .then(data => {
                        if (data.worked) {
                            this.props.onDelete(data);
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        }
    }


    render() {
        const mapIcon = this.state.textIcon ? this.state.textIcon : <img height={24} alt="default location marker" src="/public/map_default_location.png" />
        return (
            <div>
                <Modal
                    open={this.state.editMapIconModalOpen}
                    onClose={() => this.setState({ editMapIconModalOpen: false })}
                >
                    <div>
                        <Paper style={{ width: 280 }}>
                            <Picker native onEmojiClick={(event, chosenEmoji) => {
                                console.log(event)
                                console.log(chosenEmoji)
                                this.setState({ textIcon: chosenEmoji.emoji, editMapIconModalOpen: false })
                            }} />
                            <Button onClick={() => this.setState({ textIcon: "", editMapIconModalOpen: false })}>Clear</Button>
                        </Paper>
                    </div>
                </Modal>
                <h2>{this.state._id ? "Edit Location" : "Add Location"}</h2>
                <Button
                    variant="outlined"
                    style={{ marginBottom: 10 }}
                    onClick={() => this.setState({ editMapIconModalOpen: true })}
                >
                    {mapIcon}
                </Button>
                <form noValidate autoComplete="off">
                    <div>
                        <TextField error={!!this.state.errors["name"]} defaultValue={this.state.name} label="Name" onBlur={this.handleChange('name')} />
                    </div>
                    <div>
                        <TextField error={!!this.state.errors["lat"]} defaultValue={this.state.lat} label="Latitude" onBlur={this.handleChange('lat')} />&nbsp;
                        <TextField error={!!this.state.errors["lng"]} defaultValue={this.state.lng} label="Longitude" onBlur={this.handleChange('lng')} />
                    </div>
                    <div>
                        <TextField defaultValue={this.state.description} multiline fullWidth label="Description (Optional)" onBlur={this.handleChange('description')} />
                    </div>
                    <div>
                        {Object.values(this.state.errors).map((error) => <p>{error}</p>)}
                    </div>
                    <div>
                        <Button onClick={this.handleCancel} color="default">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            Save
                        </Button>
                        {this.state._id &&
                            <Button style={{ float: 'right' }} onClick={this.handleDelete} color="secondary">
                                Delete
                            </Button>
                        }
                    </div>
                </form>
            </div>
        );
    }
}