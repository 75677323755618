import React from 'react'
import ImageGallery from 'react-image-gallery'

import "react-image-gallery/styles/css/image-gallery.css";

//accepts props.images, an array of image ids to get
//from the server
export default class EventImageGallery extends React.Component {
    render() {
        return (
            <div style={{width: 550}}>
                <ImageGallery
                    items={
                        this.props.images.map((eventImage) => {
                            return {
                                original: `/api/image/${eventImage.image}`,
                                thumbnail: `/api/image/${eventImage.image}`,
                                // original: `/api/image/601ddbb43db9023020dddb9e`,
                                // thumbnail: `/api/image/601ddbb43db9023020dddb9e`
                                sizes: "400px",

                            }
                        })
                    }
                />
            </div>
        )
    }
}