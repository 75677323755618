import React from 'react';
import DropzoneComponent from 'react-dropzone-component';
import './ImageDropzone.css'
import 'react-dropzone-component/styles/filepicker.css'
import './dropzone.min.css'

export default class ImageDropzone extends React.Component {
    constructor(props) {
        super(props);

        // For a full list of possible configurations,
        // please consult http://www.dropzonejs.com/#configuration

        const params = {}
        params.mapId = this.props.mapId;
        if (this.props.eventId) {
            params.eventId = this.props.eventId
        }

        this.djsConfig = {
            addRemoveLinks: true,
            acceptedFiles: "image/jpeg,image/png,image/gif,image/webp",
            autoProcessQueue: this.props.autoProcessQueue,
            autoQueue: true,
            maxFilesize: 10, //1MB. Until we figure out how to increase nginx limit we are stuck at 1MB
            params: params,
            parallelUploads: 1
        };

        this.componentConfig = {
            showFiletypeIcon: true,
            postUrl: this.props.postUrl,
        };
        this.state = {
            dropzone: null
        }
    }

    handleFileAdded(file) {
        console.log(file);
    }

    handleInit(dropzone) {
        console.log(dropzone)
        this.setState({ dropzone: dropzone })
        dropzone.on('success', (file, response) => {
            console.log("response", response)
            this.props.onImagesUpload(response.images)
            this.state.dropzone.options.autoProcessQueue = true //HACKY
            return response
        })
    }


    //many of the following functions were meant for less hacky code.
    //we keep them in case we can ever go back.
    on(...params) {
        return this.state.dropzone.on(...params)
    }

    setEventId(eventId) {
        this.state.dropzone.on("sending", function (file, xhr, formData) {
            formData.append("eventId", eventId)
        })
        this.state.dropzone.processQueue();
    }

    hasQueue() {
        return (this.state.dropzone.getQueuedFiles.length > 0)
    }

    render() {
        const config = this.componentConfig;
        const djsConfig = this.djsConfig;

        // For a list of all possible events (there are many), see README.md!
        const eventHandlers = {
            init: this.handleInit.bind(this),
            // success: (a, response) => {
            //     console.log("response", response)
            //     this.props.onImagesUpload(response.images)
            //     this.state.dropzone.options.autoProcessQueue = true
            //     return response
            // }
        }

        return <div><DropzoneComponent config={config} eventHandlers={eventHandlers} djsConfig={djsConfig} /></div>
    }
}